.body2 {
    background-color: rgb(236, 240, 241);
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;
height: 100%;
}

.main {
    background-color: #ffffff;
    width: 400px;
    height:auto;
    margin: 7em auto;
    border-radius: 0.2em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    padding-bottom: 20px;
}

.sign {
    padding-top: 0px;
    color: #8c55aa;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
    padding-top: 20px;

}
form{
    margin: 0;
}

.un {
    width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 0.2em;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 18px;
    font-family: 'Ubuntu', sans-serif;
}

form.form1 {
    padding-top: 0px;
    margin-top: -2px;
}

.pass {
    width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 0.2em;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
}

.un:focus,
.pass:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.submit {
    cursor: pointer;
    border-radius: 0.2em;
    color: #fff;
    background: linear-gradient(to right, #1890FF, #46A6FF);
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Ubuntu', sans-serif;
    margin-left: 35%;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    text-decoration: none;
}
.submit:focus {
    outline: none;
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #e1bee7;
    padding-top: 15px;
}

a {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #e1bee7;
    text-decoration: none;
}

@media (max-width: 600px) {
    .main {
        border-radius: 0px;
    }
}

label.label1 {
    padding-left: 12%;
}
label.label2 {
    padding-left: 5.6%;
}
.rememberLogin {
    display: flex;
    align-items: center;
    margin-bottom: -8px;
}
.loginIcons {
    display: flex;
    justify-content: center;
    font-weight: 500;
    line-height: 1.8;

  }

  .loginIcons {
    padding-left: 10%;
    padding-right: 14%;
}  
.textStyle {
   color: rgba(0, 0, 0, 0.65)
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: right;
    height: 30px;
  }

  hr {
    border-top: 1px  rgba(0, 0, 0, 0.65);
}
.failedLogin {
    margin-top: -18px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: thin;
    border-color: #fbcfc6;
    background: #FFF2F0;
    padding: 4px;
    margin-left: 45px;
    margin-right: 45px;
    font-size: 14px;
    border-radius: 20px;
}

.icons {
    display: flex;
    align-items: center;
  }

  span.eyeShow {
    margin-left: -45px;
}